<template>
  <Suspense suspensible>
    <MDCRenderer
      v-if="!!snippetName && ast && !snippetError && !parseMarkdownError"
      :id="componentId"
      :body="ast.body!"
      class="page-snippet"
      :data="snippetData"
      :data-snippet="snippetName"
      :data-testid="snippetName"
      :unwrap="unwrap"
    />
  </Suspense>
</template>

<script setup lang="ts">
/**
 * This component is exposed as `snippet` in MDC markdown files
 */
import { parseMarkdown } from '@nuxtjs/mdc/runtime'
import type { MDCParserResult, MDCRoot } from '@nuxtjs/mdc'
import { defu } from 'defu'
import type { ComputedRef } from 'vue'
import type { PageSnippetProps } from '#imports'

const {
  name,
  data = {},
  inheritData = null,
  unwrap,
  styles = '',
} = defineProps<PageSnippetProps>()

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)
// Determine the snippet name
const snippetName = computed((): string => String(name || '').trim() || '')

// Provide/inject parent nodes to filter out
const snippetParentNodes = useState(`page-snippet-${componentId}`, () => new Set([...inject(SnippetParentNodesInjectionKey, new Set()), snippetName.value].filter(item => item.trim() !== '')))

// Provide the snippet name to the child nodes
provide(SnippetParentNodesInjectionKey, new Set([...snippetParentNodes.value].filter(item => item.trim() !== '')))

const { t } = useI18n()
const fetchKey = computed((): string => `portal-snippet-${(snippetName.value || componentId).replace(/\//g, '-')}`)
const { transform, getCachedData } = serveCachedData()

const { data: snippet, error: snippetError } = await usePortalApi('/api/v3/snippets/{snippetName}', {
  headers: {
    accept: 'application/json',
  },
  path: {
    snippetName: snippetName.value,
  },
  // Reuse the same key to avoid re-fetching the document, e.g. `portal-page-about`
  key: fetchKey.value,
  pick: ['content'],
  transform,
  getCachedData,
  dedupe: 'defer', // Ensure snippets are only fetched once as needed
  immediate: !!snippetName.value, // Do not immediately fetch in case there's no snippet name
  retry: false, // Do not retry on 404 in case the snippet doesn't exist
})

// Get a pipe-delimited list of parent snippet names
const nodes = [...snippetParentNodes.value].join('|')
// Strip out recursive snippet(s), looking for `name` prop in the content
const snippetRegex = new RegExp(`(name)(=|:)( )?('|"|\`)?('|"|\`)?(${nodes})('|"|\`)?('|"|\`)?`, 'i')
// !Important: This **must** remain a single space character for the replacement logic below.
const snippetReplacementString = String(' ')
// Important: Replace $6 with an empty string to remove the snippet name from the content; must be a space to avoid evaluating as a boolean prop.
const sanitizedSnippet = snippet.value?.content?.replace(snippetRegex, `$1$2$3$4$5${ snippetReplacementString }$7`) || ''

const removeInvalidSnippets = (obj: Record<string, any>): Record<string, any> | null => {
  if (Array.isArray(obj)) {
    // Recursively handle arrays by filtering out invalid objects and processing children
    return obj
      .map(item => removeInvalidSnippets(item))
      .filter(item => item !== null)
  } else if (typeof obj === 'object' && obj !== null) {
    // Check if the object has 'tag' property and the required 'props.name' condition
    if (
      obj.tag &&
      // If the tag matches `snippet` or `page-snippet` (this component)
      (obj.tag === 'snippet' || obj.tag === 'page-snippet') &&
      (obj.props && typeof obj.props === 'object') &&
      ('name' in obj.props && typeof obj.props.name === 'string') &&
      // If name is empty, or includes a parent snippet with the same name
      (obj.props.name.trim() === '' || snippetParentNodes.value.has(obj.props.name))
    ) {
      // Remove the object if it matches the criteria
      return null
    }

    // Recursively process each key of the object
    const newObj: Record<string, any> = {}
    for (const key in obj) {
      // Using the 'in' operator to check for the existence of the property
      if (key in obj) {
        const result = removeInvalidSnippets(obj[key])
        if (result !== null) {
          newObj[key] = result
        }
      }
    }
    return newObj
  }

  // Return other types (strings, numbers, etc.) as-is
  return obj
}

const { data: ast, error: parseMarkdownError } = await useAsyncData(`parsed-${fetchKey.value}`, async (): Promise<MDCParserResult> => {
  const parsed = await parseMarkdown(sanitizedSnippet || '<span class="snippet-likely-empty" />', {
    toc: {
      depth: 2,
      searchDepth: 2,
    },
  })

  // Extract the `body` and destructure the rest of the document
  const { body, ...parsedDoc } = parsed

  // Important: Remove invalid snippets from the AST
  const processedBody = removeInvalidSnippets(body)

  // Return the MDCParserResult with the sanitized body
  return {
    ...parsedDoc,
    body: processedBody as MDCRoot,
  }
}, {
  // Do not immediately process the snippet data if all conditions are not met
  immediate: !!snippetName.value && (!!sanitizedSnippet && !!snippet.value) && !snippetError.value,
  dedupe: 'defer',
  deep: false,
  // Important: utilize the cached data
  transform,
  getCachedData,
})

// Determine if any parent snippet sets `inheritData` to `true`
const setChildrenInheritToTrue = inject(SnippetShouldInheritDataInjectionKey, null)
// Indicates if the current snippet should inherit parent properties, default to `false`
const inheritParentProps = useState<boolean>(`inherit-parent-props-${componentId}`, () => false)

// Provide `true` to any child snippets if the current snippet has `inheritData` set to `true`, or if the parent snippet has `inheritData` set to `true` and the current snippet does not have `inheritData` set to `false`
if (
  inheritData === true ||
  (ast.value?.data?.inheritData === true && inheritData !== false) ||
  (setChildrenInheritToTrue === true && inheritData !== false && ast.value?.data?.inheritData !== false)
) {
  // Set the `inheritParentProps` to `true` to indicate that the current snippet should inherit the parent's properties
  inheritParentProps.value = true
  // Signal to the child snippets that they should inherit the parent's properties until a child sets `inheritData` to `false`
  provide(SnippetShouldInheritDataInjectionKey, true)
}

const parentSnippetData = inject<ComputedRef<Record<string, any>> | undefined>(SnippetParentDataInjectionKey, computed(() => ({})))
const parentPageData = inject(SnippetPageDataInjectionKey, computed(() => ({ title: '', description: '' })))

// Provide the snippet data to the renderer by merging the AST data with `props.data`. Access via `$doc.snippet.{propertyName}`
const snippetData = computed((): { page: Record<string, any>, snippet: Record<string, any> } => {
  let inheritedData = {}
  if (inheritParentProps.value && parentSnippetData?.value?.snippet) {
    const {
      // Discard the parent's `title`, `description`, and `inheritData` properties
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      title, description, inheritData,
      // Destructure the rest of the parent data
      ...parentData
    } = parentSnippetData.value.snippet
    inheritedData = { ...parentData }
  }

  // Discard the `inheritData` property so it is not injected into snippetData
  // @ts-ignore - `inheritData` is not a valid prop
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { inheritData, ...frontmatter } = ast.value?.data || {}

  return {
    // Always inject the parent page data
    page: parentPageData?.value || {},
    // Merge the props data with the inherited data and the AST data (ordered by priority, with `props.data` taking precedence)
    snippet: defu(data, frontmatter, inheritedData),
  }
})

// Always provide the current snippet's data to the child nodes.
// Important: Provide the ComputedRef to avoid reactivity issues.
provide(SnippetParentDataInjectionKey, snippetData)

// Log the snippet error if it exists, but do not throw so that the snippet silently fails and the rest of the page continues to render.
if (snippetName.value && (snippetError.value || parseMarkdownError.value)) {
  // Determine the error message
  const errMessage = snippetError.value ? parseApiError(snippetError.value as any) : parseMarkdownError.value?.message || t('errors.snippets.parser')

  // postMessage the error up to the parent window
  postPreviewModeMessage({
    action: 'portal:error:snippet',
    snippet_name: snippetName.value,
    error: errMessage,
  })
  // Log the snippet error to the console
  console.warn(`snippet(name:'${ snippetName.value }')`, errMessage)
}
</script>
