import type { InjectionKey } from 'vue'
import type { MDCData } from '@nuxtjs/mdc'

/** Injection key for providing the names of the current snippet's parent nodes. */
export const SnippetParentNodesInjectionKey: InjectionKey<Set<string>> = Symbol('snippet-parent-nodes')
/** Injection key to indicate if parent snippet(s) support inheriting data. */
export const SnippetShouldInheritDataInjectionKey: InjectionKey<boolean | null> = Symbol('snippet-should-inherit-parent-data')
/** Injection key for providing parent snippet data to child nodes. */
export const SnippetParentDataInjectionKey: InjectionKey<ComputedRef<Record<string, any>>> = Symbol('snippet-parent-data')

/** Injection key for providing parent page data to child snippet(s). */
export const SnippetPageDataInjectionKey: InjectionKey<ComputedRef<MDCData> | undefined> = Symbol('snippet-page-data')
